import { Stripe, loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { getStripePublicKey } from "../util/stripe";
import { Elements } from "@stripe/react-stripe-js";
import { gql, useMutation } from "@apollo/client";
import { Cell, CircularSpinner, View, useAuthState } from "@barscience/global-components";

const CREATE_PAYMENT_SETUP_INTENT = gql`
mutation createPaymentSetupIntent($orgId: ID!) {
  clientKey: createPaymentSetupIntent(orgId: $orgId)
}
`;

type CreatePaymentSetupIntentResponse = {
  clientKey: string | null;
}

export type PaymentSetupProviderProps = {
  children: React.ReactNode;
}

export default function PaymentSetupProvider(props: PaymentSetupProviderProps) {
  const { state } = useAuthState();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const [createPaymentSetup] = useMutation<CreatePaymentSetupIntentResponse>(CREATE_PAYMENT_SETUP_INTENT);

  useEffect(() => {
    const stripePromise = loadStripe(getStripePublicKey());
    setStripePromise(stripePromise);
  }, []);

  useEffect(() => {
    createPaymentSetup({
      variables: {
        orgId: state.user?.org?.id,
      },
    }).then(({ data }) => {
      setClientSecret(data?.clientKey ? data.clientKey : undefined);
    });
  }, [createPaymentSetup, state.user?.org?.id]);

  if (!stripePromise || !clientSecret) {
    return (
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <CircularSpinner />
        </View>
      </Cell>
    );
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
      {props.children}
    </Elements>
  );
}