import { Reference, gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, AutoOpen, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Choice, CircularSpinner, Colors, ConfirmModal, FormModal, FormModalValueProvider, HasProductRole, Icon, Icons, InfoPanel, ModalLauncher, NotFound, OrgRoles, PageButtons, Products, Row, SingleSelect, StandardAlert, StandardGrid, StyledCaption, StyledHeading, StyledParagraph, TextField, View, generateId, useAlertState, useAuthState } from "@barscience/global-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TimeAgo from 'react-timeago';

/* Get User Profile Query */
const USER_PROFILE_QUERY = gql`
query getEmployeeProfile($id: ID!) {
  user(id: $id) {
    id
    firstName
    lastName
    email
    phone
    address {
      streetAddress
      city
      state
      zipcode
    }
    dateOfBirth
    isInactive
    created
    org {
      id
      name
    }
    productRoles {
      id
    }
    isOrgPrimaryContact
    isOrgBillingContact
  }
}
`;

type UserProfileResponse = {
  user: User | null;
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: Address | null;
  dateOfBirth: string;
  isInactive: boolean;
  created: string;
  org: Org | null;
  productRoles: {
    id: string;
  }[];
  isOrgPrimaryContact: boolean | null;
  isOrgBillingContact: boolean | null;
}

type Org = {
  id: string;
  name: string;
}

type Address = {
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
}

/* Get User Change Logs Query */
const USER_CHANGE_LOGS_QUERY = gql`
query getUserChangeLogs($id: ID!, $page: Int!) {
  userChangeLogs(id: $id, page: $page) {
    logs {
      timestamp
      description
    }
    pages
  }
}
`;

type UserChangeLogsResponse = {
  userChangeLogs: {
    logs: UserChangeLog[];
    pages: number;
  } | null;
}

type UserChangeLog = {
  timestamp: string;
  description: string;
}

/* Get Plans Query */
const GET_PLANS = gql`
query getPlansForOrgUserManagement($orgId: ID!) {
  org(id: $orgId) {
    plans {
      plan {
        id
        name
        type
        cost
        costType
        userLimit
        product {
          id
          name
          roles {
            id
            name
            description
            isBillable
          }
        }
      }
      countBillableUsers
    }
  }
}
`;

type GetPlansResponse = {
  org: {
    plans: {
      plan: Plan;
      countBillableUsers: number;
    }[];
  } | null;
}

type Plan = {
  id: string;
  name: string;
  type: 'ANNUAL' | 'MONTHLY';
  cost: string;
  costType: 'FLAT' | 'PER_USER';
  userLimit: number | null;
  product: Product;
}

type Product = {
  id: string;
  name: string;
  roles: ProductRole[];
}

type ProductRole = {
  id: string;
  name: string;
  description: string | null;
  isBillable: boolean;
}

/* Add Product Role */
const ADD_PRODUCT_ROLE = gql`
mutation addUserProductAccess($userId: ID!, $productId: ID!, $roleId: ID!) {
  addUserProductAccess(userId: $userId, productId: $productId, roleId: $roleId) {
    id
    name
    description
  }
}
`;

type AddProductRoleResponse = {
  addUserProductAccess: ProductRole | null;
}

type AddProductRoleInput = {
  productId: string;
  roleId: string;
}

/* Edit Product Role */
const EDIT_PRODUCT_ROLE = gql`
mutation editUserProductAccess($userId: ID!, $productId: ID!, $roleId: ID!) {
  editUserProductAccess(userId: $userId, productId: $productId, roleId: $roleId) {
    id
    name
    description
  }
}
`;

type EditProductRoleResponse = {
  editUserProductAccess: ProductRole | null;
}

/* Remove Product Role */
const REMOVE_PRODUCT_ROLE = gql`
mutation removeUserProductAccess($userId: ID!, $productId: ID!) {
  success: removeUserProductAccess(userId: $userId, productId: $productId)
}
`;

type RemoveProductRoleResponse = {
  success: boolean;
}

/* Deactivate User Mutation */
const DEACTIVATE_USER = gql`
mutation deactivateUser($id: ID!) {
  deactivateUser(id: $id) {
    id
    isInactive
  }
}
`;

type DeactivateUserResponse = {
  deactivateUser: {
    id: string;
    isInactive: boolean;
  } | null;
}

/* Activate User Mutation */
const ACTIVATE_USER = gql`
mutation activateUser($id: ID!) {
  activateUser(id: $id) {
    id
    isInactive
  }
}
`;

type ActivateUserResponse = {
  activateUser: {
    id: string;
    isInactive: boolean;
  } | null;
}

export default function EmployeeDetails() {
  const { addAlert } = useAlertState();
  const { state } = useAuthState();
  const { userId } = useParams();
  const [roleChangeId, setRoleChangeId] = useState<string | null>(null);
  const [changeLogsPage, setChangeLogsPage] = useState<number>(0);
  const { data: userData, loading: userIsLoading } = useQuery<UserProfileResponse>(USER_PROFILE_QUERY, {
    variables: {
      id: userId,
    },
  });
  const { data: userLogsData, loading: userLogsAreLoading } = useQuery<UserChangeLogsResponse>(USER_CHANGE_LOGS_QUERY, {
    variables: {
      id: userId,
      page: changeLogsPage,
    },
  });
  const [getOrgPlans, { data: orgPlansData, loading: orgPlansAreLoading }] = useLazyQuery<GetPlansResponse>(GET_PLANS);
  const [addProductRole] = useMutation<AddProductRoleResponse>(ADD_PRODUCT_ROLE, {
    update(cache, { data }, { variables }) {
      if (!userData?.user || !variables?.productId) {
        return;
      }

      cache.modify({
        id: cache.identify(userData.user),
        fields: {
          productRoles(existingRoles = [], { readField }) {
            const newRoleRef = cache.writeFragment({
              data: data?.addUserProductAccess,
              fragment: gql`
                fragment NewRole on ProductRole {
                  id
                  name
                  description
                }
              `
            });

            return [...existingRoles, newRoleRef];
          }
        }
      });
    },
  });
  const [editProductRole] = useMutation<EditProductRoleResponse>(EDIT_PRODUCT_ROLE, {
    update(cache, { data }, { variables }) {
      if (!userData?.user || !variables?.productId) {
        return;
      }

      cache.modify({
        id: cache.identify(userData.user),
        fields: {
          productRoles(existingRoles = [], { readField }) {
            const newRoleRef = cache.writeFragment({
              data: data?.editUserProductAccess,
              fragment: gql`
                fragment NewRole on ProductRole {
                  id
                  name
                  description
                }
              `
            });

            const prevRoles = existingRoles.filter((roleRef: Reference) => {
              const roleId = readField('id', roleRef);

              const plan = orgPlansData?.org?.plans.find((plan) => {
                for (const role of plan.plan.product.roles) {
                  if (role.id === roleId) {
                    return true;
                  }
                }

                return false;
              });

              if (plan?.plan.product.id === variables.productId) {
                return false;
              }

              return true;
            });

            return [...prevRoles, newRoleRef];
          }
        }
      });
    },
    optimisticResponse(vars) {
      const unsuccessfulResponse = {
        editUserProductAccess: null,
      };

      if (!vars?.productId || !vars?.roleId) {
        return unsuccessfulResponse;
      }

      const plan = orgPlansData?.org?.plans.find((plan) => plan.plan.product.id === vars.productId);
      if (!plan) {
        return unsuccessfulResponse;
      }

      const role = plan.plan.product.roles.find((role) => role.id === vars.roleId);
      if (!role) {
        return unsuccessfulResponse;
      }

      return {
        editUserProductAccess: role,
      }
    },
  });
  const [removeProductRole] = useMutation<RemoveProductRoleResponse>(REMOVE_PRODUCT_ROLE, {
    update(cache, { data }, { variables }) {
      if (!userData?.user || !variables?.productId) {
        return;
      }

      cache.modify({
        id: cache.identify(userData.user),
        fields: {
          productRoles(existingRoles = [], { readField }) {
            return existingRoles.filter((roleRef: Reference) => {
              const roleId = readField('id', roleRef);

              const plan = orgPlansData?.org?.plans.find((plan) => {
                for (const role of plan.plan.product.roles) {
                  if (role.id === roleId) {
                    return true;
                  }
                }

                return false;
              });

              if (plan?.plan.product.id === variables.productId) {
                return false;
              }

              return true;
            });
          }
        }
      });
    },
    optimisticResponse: {
      success: true
    },
  });
  const [deactivateUser] = useMutation<DeactivateUserResponse>(DEACTIVATE_USER, {
    update(cache, { data }) {
      // When a user is deactivated, all of their product roles are deleted

      if (!userData?.user) {
        return;
      }

      cache.modify({
        id: cache.identify(userData.user),
        fields: {
          productRoles() {
            return [];
          },
        },
      });
    },
  });
  const [activateUser] = useMutation<ActivateUserResponse>(ACTIVATE_USER);

  useEffect(() => {
    if (!userData?.user?.org?.id) {
      return;
    }

    getOrgPlans({
      variables: {
        orgId: userData.user.org.id,
      },
    });
  }, [userData?.user?.org?.id, getOrgPlans]);

  /* Add Product Role */
  const handleAddProductRole = async (values: AddProductRoleInput) => {
    const { errors } = await addProductRole({
      variables: {
        userId: userId,
        productId: values.productId,
        roleId: values.roleId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to add role' description={errors[0].message} type='error' id={id} />

      addAlert(id, alert, 60);
    }
  }

  const addProductRoleModal = (
    <FormModal<AddProductRoleInput> title='Add Role' subtitle='Give the user access to a product' submitLabel='Add Role' onSubmit={handleAddProductRole} initialValues={{ productId: '', roleId: '' }}>
      <FormModalValueProvider>
        {({ getValue, setError }) => (
          <>
            {(() => {
              if (!getValue) {
                return <></>;
              }

              const plans = orgPlansData?.org?.plans?.filter((plan) => {
                for (const role of plan.plan.product.roles) {
                  if (userData?.user?.productRoles.find((userRole) => userRole.id === role.id)) {
                    return false;
                  }
                }

                if (plan.plan.product.id === 'org' && state.user?.roles[Products.Org] !== OrgRoles.Owner) {
                  return false;
                }

                return true;
              });

              const selectedProduct = orgPlansData?.org?.plans?.find((plan) => plan.plan.product.id === getValue('productId'));
              const selectedRole = selectedProduct?.plan.product.roles.find((role) => role.id === getValue('roleId'));

              if (!plans || plans?.length === 0) {
                return (
                  <>
                    <StyledParagraph>Your org is not subscribed to any products that the user does not have access to.</StyledParagraph>
                    <TextField name='' required style={{ display: 'none' }} />
                  </>
                );
              }

              return (
                <View style={{ gap: '32px' }}>
                  <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                    <SingleSelect label='Product' name='productId' style={{ maxWidth: 'calc(50% - 16px)', width: '50%', '@media (max-width: 767px)': { maxWidth: '100%', width: '100%' } }} validate={() => { setError && setError('roleId', ''); return null; }} required>
                      {orgPlansData?.org?.plans?.filter((plan) => {
                        for (const role of plan.plan.product.roles) {
                          if (userData?.user?.productRoles.find((userRole) => userRole.id === role.id)) {
                            return false;
                          }
                        }

                        return true;
                      }).map((plan, index) => (
                        <Choice label={plan.plan.product.name} value={plan.plan.product.id.toString()} key={index} />
                      ))}
                    </SingleSelect>

                    {selectedProduct && (
                      <SingleSelect label='Role' name='roleId' style={{ width: '50%', '@media (max-width: 767px)': { width: '100%' } }} required>
                        {selectedProduct.plan.product.roles.map((role, index) => (
                          <Choice label={role.name} description={role.description || undefined} value={role.id.toString()} key={index} />
                        ))}
                      </SingleSelect>
                    )}
                  </View>

                  {(selectedProduct?.plan.costType === 'PER_USER' && selectedRole?.isBillable) &&
                    <InfoPanel type='warning'>
                      <View style={{ gap: '16px' }}>
                        <StyledParagraph>Giving this user access to this product will increase your billing costs by {selectedProduct.plan.cost} / {selectedProduct.plan.type === 'MONTHLY' ? 'month' : 'year'}.</StyledParagraph>
                      </View>
                    </InfoPanel>
                  }

                  {selectedProduct &&
                    <InfoPanel type='info'>
                      <View style={{ gap: '16px' }}>
                        {selectedProduct.plan.userLimit === null ?
                          <StyledParagraph>You have <span style={{ fontWeight: 600 }}>unlimited seats remaining</span> for this product.</StyledParagraph>
                          :
                          <StyledParagraph>You have <span style={{ fontWeight: 600 }}>{selectedProduct.plan.userLimit - selectedProduct.countBillableUsers} seats remaining</span> for this product.</StyledParagraph>
                        }
                      </View>
                    </InfoPanel>
                  }
                </View>
              );
            })()}
          </>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  /* Change Product Role */
  const handleChangeProductRole = (_: string, roleId: string | null) => {
    if (!roleId) {
      return;
    }

    const plan = orgPlansData?.org?.plans.find((plan) => {
      for (const role of plan.plan.product.roles) {
        if (role.id === roleId) {
          return true;
        }
      }

      return false;
    });
    if (!plan) {
      return;
    }

    const previousRoleId = userData?.user?.productRoles.find((role) => {
      for (const productRole of plan.plan.product.roles) {
        if (productRole.id === role.id) {
          return true;
        }
      }

      return false;
    });
    if (!previousRoleId) {
      return;
    }

    const previousRole = plan.plan.product.roles.find((role) => role.id === previousRoleId.id);
    if (!previousRole) {
      return;
    }

    const newRole = plan.plan.product.roles.find((role) => role.id === roleId);
    if (!newRole) {
      return;
    }

    // If changing from a non-billable role to a billable role, we prompt the user to confirm the change
    if (!previousRole.isBillable && newRole.isBillable) {
      setRoleChangeId(roleId);
      return;
    }

    // If the user is already on a billable role or is changing to a non-billable role, then no confirmation is necessary
    handleConfirmChangeRole(roleId);
  }

  const handleConfirmChangeRole = async (roleId: string) => {
    if (!roleId) {
      return;
    }

    const plan = orgPlansData?.org?.plans.find((plan) => {
      for (const role of plan.plan.product.roles) {
        if (role.id === roleId) {
          return true;
        }
      }

      return false;
    });

    if (!plan) {
      return;
    }

    const { errors } = await editProductRole({
      variables: {
        userId: userId,
        productId: plan.plan.product.id,
        roleId: roleId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to change role' type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Role updated' type='success' id={id} />
      addAlert(id, alert);
    }

    setRoleChangeId(null);
  }

  const confirmChangeRoleModal = () => {
    const selectedProduct = orgPlansData?.org?.plans.find((plan) => {
      for (const role of plan.plan.product.roles) {
        if (role.id === roleChangeId) {
          return true;
        }
      }

      return false;
    });

    return (
      <ConfirmModal title='Change Role?' confirmLabel='Confirm Change' onConfirm={async () => { await handleConfirmChangeRole(roleChangeId || ''); }} onCancel={() => { setRoleChangeId(null); }}>
        <View style={{ gap: '16px' }}>
          <StyledParagraph>You are changing from a non-billable to a billable role.</StyledParagraph>

          <InfoPanel type='warning'>
            <View style={{ gap: '16px' }}>
              <StyledParagraph>Changing this user's role will increase your billing costs by {selectedProduct?.plan.cost} / {selectedProduct?.plan.type === 'MONTHLY' ? 'month' : 'year'}.</StyledParagraph>
            </View>
          </InfoPanel>
        </View>
      </ConfirmModal>
    );
  }

  /* Remove Product Role */
  const handleRemoveProductRole = async (roleId: string) => {
    const plan = orgPlansData?.org?.plans.find((plan) => {
      for (const role of plan.plan.product.roles) {
        if (role.id === roleId) {
          return true;
        }
      }

      return false;
    });

    if (!plan) {
      return;
    }

    const { errors } = await removeProductRole({
      variables: {
        userId: userId,
        productId: plan.plan.product.id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to remove role' type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const removeProductRoleModal = (
    <ConfirmModal title='Remove role?' confirmLabel='Remove' onConfirm={handleRemoveProductRole}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This role will be removed from the user and they will no longer have access to this product.</StyledParagraph>
        <StyledParagraph>If this was a billable role, you will receive a prorated credit on your next invoice.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  /* Deactivate User */
  const handleDeactivateUser = async () => {
    const { errors } = await deactivateUser({
      variables: {
        id: userId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to deactivate user' description={errors[0].message} type='error' id={id} />

      addAlert(id, alert, 60);
    }
  }

  const deactivateUserModal = (
    <ConfirmModal title='Deactivate User?' confirmLabel='Deactivate' onConfirm={handleDeactivateUser} destructive>
      <StyledParagraph>The user's account will be deactivated. The user will not be able to login to their account and will lose access to all products.</StyledParagraph>
    </ConfirmModal>
  );

  /* Activate User */
  const handleActivateUser = async () => {
    const { errors } = await activateUser({
      variables: {
        id: userId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to activate user' description={errors[0].message} type='error' id={id} />

      addAlert(id, alert, 60);
    }
  }

  const activateUserModal = (
    <ConfirmModal title='Activate User?' confirmLabel='Activate' onConfirm={handleActivateUser}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>The user's account will be activated. The user will be able to login to their account and will regain access to all products.</StyledParagraph>
        <StyledParagraph bold>A seat will be added for all products that the user currently has access to, which may increase your billing costs.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  if (userIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!userData?.user) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      {roleChangeId !== null &&
        <ModalLauncher modal={confirmChangeRoleModal()}>
          {({ openModal }) => (
            <AutoOpen openModal={openModal} />
          )}
        </ModalLauncher>
      }

      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Employees' to='/employees' />
          <Breadcrumb label={`${userData.user.firstName} ${userData.user.lastName}`} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
          <StyledHeading tag='h3'>{userData.user.firstName} {userData.user.lastName}</StyledHeading>
        </View>
      </Cell>
      <Row>
        <Cell lg={6} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h6'>Profile Info</StyledHeading>
              <View>
                <StyledCaption>Email</StyledCaption>
                <StyledParagraph>{userData.user.email}</StyledParagraph>
              </View>
              <View>
                <StyledCaption>Phone</StyledCaption>
                <StyledParagraph>{userData.user.phone}</StyledParagraph>
              </View>
              <View>
                <StyledCaption>Address</StyledCaption>
                <StyledParagraph>{userData.user.address?.streetAddress}</StyledParagraph>
                <StyledParagraph>{userData.user.address?.city}, {userData.user.address?.state} {userData.user.address?.zipcode}</StyledParagraph>
              </View>
              <View>
                <StyledCaption>Date of Birth</StyledCaption>
                <StyledParagraph>{userData.user.dateOfBirth}</StyledParagraph>
              </View>
            </View>
          </Card>
        </Cell>
        <Cell lg={6} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h6'>Account Info</StyledHeading>
              <View>
                <StyledCaption>Status</StyledCaption>
                {userData.user.isInactive ?
                  <View style={{ flexDirection: 'row', gap: '16px' }}>
                    <StyledParagraph style={{ color: Colors.error500, fontWeight: 600 }}>INACTIVE</StyledParagraph>
                    <ModalLauncher modal={activateUserModal}>
                      {({ openModal }) => (
                        <HasProductRole product={Products.Org} roles={[OrgRoles.Manager, OrgRoles.BillingManager, OrgRoles.Owner]}>
                          <Button label='Activate' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} />
                        </HasProductRole>
                      )}
                    </ModalLauncher>
                  </View>
                  :
                  <View style={{ flexDirection: 'row', gap: '16px' }}>
                    <StyledParagraph style={{ color: Colors.primary500, fontWeight: 600 }}>ACTIVE</StyledParagraph>
                    <ModalLauncher modal={deactivateUserModal}>
                      {({ openModal }) => (
                        <HasProductRole product={Products.Org} roles={[OrgRoles.Manager, OrgRoles.BillingManager, OrgRoles.Owner]}>
                          <Button label='Deactivate' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} destructive disabled={userId === state.user?.id || userData.user?.isOrgBillingContact || userData.user?.isOrgPrimaryContact || false} />
                        </HasProductRole>
                      )}
                    </ModalLauncher>
                  </View>
                }
              </View>
              <View>
                <StyledCaption>Created</StyledCaption>
                <StyledParagraph>{new Date(userData.user.created).toLocaleString()}</StyledParagraph>
              </View>
            </View>
          </Card>
        </Cell>
      </Row>
      <Row>
        <Cell lg={6} md={8} sm={4}>
          <Card size='medium'>
            {(orgPlansAreLoading || userIsLoading) ?
              <View style={{ alignItems: 'center' }}>
                <CircularSpinner size='medium' />
              </View>
              :
              <View style={{ gap: '16px', '@media (max-width: 767px)': { gap: '32px' } }}>
                <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <StyledHeading tag='h6'>Product Access</StyledHeading>
                  <ModalLauncher modal={addProductRoleModal}>
                    {({ openModal }) => (
                      <Button label='Add Role' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} disabled={userData.user?.isInactive} />
                    )}
                  </ModalLauncher>
                </View>
                {orgPlansData?.org?.plans.map((plan, index) => {
                  const userRole = userData?.user?.productRoles.find((role) => {
                    return plan.plan.product.roles.some((productRole) => productRole.id === role.id);
                  });

                  if (userRole === undefined) {
                    return <></>;
                  }

                  return (
                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px', '@media (max-width: 767px)': { alignItems: 'flex-start', flexDirection: 'column', gap: '8px' } }} key={index}>
                      <View style={{ flexDirection: 'row', gap: '8px', maxWidth: '140px', minWidth: '140px', width: '140px' }}>
                        <Icon icon={getProductIcon(plan.plan.product.id)} size='medium' />
                        <StyledParagraph bold>{plan.plan.product.name}</StyledParagraph>
                      </View>

                      <SingleSelect label='' name='' value={userRole.id.toString()} style={{ maxWidth: '300px', '@media (max-width: 767px)': { width: '100%' } }} disabled={plan.plan.product.id === 'org' && (state.user?.roles.Org !== OrgRoles.Owner || userId === state.user.id || userData.user?.isOrgBillingContact || userData.user?.isOrgPrimaryContact || false)} onChange={handleChangeProductRole} >
                        {plan.plan.product.roles.map((role, index) => (
                          <Choice label={role.name} value={role.id.toString()} description={role.description || undefined} key={index} />
                        ))}
                      </SingleSelect>

                      <ModalLauncher modal={removeProductRoleModal}>
                        {({ openModal }) => (
                          <Button label='Remove' variant='tertiary' role='button' action={() => { openModal(userRole.id); }} disabled={plan.plan.product.id === 'org' && (state.user?.roles.Org !== OrgRoles.Owner || userId === state.user.id || userData.user?.isOrgBillingContact || userData.user?.isOrgPrimaryContact || false)} />
                        )}
                      </ModalLauncher>
                    </View>
                  );
                })}
              </View>
            }
          </Card>
        </Cell>
        <Cell lg={6} md={8} sm={4}>
          <Card size='medium'>
            {userLogsAreLoading ?
              <View style={{ alignItems: 'center' }}>
                <CircularSpinner size='medium' />
              </View>
              :
              <View style={{ gap: '16px', maxWidth: '100%' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <StyledHeading tag='h6'>Change Logs</StyledHeading>
                  <PageButtons currentPage={changeLogsPage} numPages={userLogsData?.userChangeLogs?.pages || 0} onPageChange={setChangeLogsPage} />
                </View>
                <View style={{ gap: '8px', maxWidth: '100%' }}>
                  {userLogsData?.userChangeLogs?.logs.map((log, index) => (
                    <View key={index} style={{ borderBottom: `1px solid ${Colors.neutral300}`, flexDirection: 'row', gap: '8px', maxWidth: '100%', paddingBottom: '16px' }}>
                      <StyledParagraph style={{ flexGrow: 1 }}>{log.description}</StyledParagraph>
                      <StyledParagraph style={{ color: Colors.neutral700, flexGrow: 0, fontSize: '14px', maxWidth: '150px', minWidth: '150px', width: '150px', wordWrap: 'break-word' }}><TimeAgo date={log.timestamp} title={log.timestamp} /></StyledParagraph>
                    </View>
                  ))}
                </View>
                {userLogsData?.userChangeLogs?.logs.length === 0 && <StyledParagraph>There are no change logs for this user.</StyledParagraph>}
              </View>
            }
          </Card>
        </Cell>
      </Row>
    </StandardGrid>
  );
}

const getProductIcon = (id: string): Icons => {
  switch (id) {
    case 'org':
      return Icons.Organization;
    case 'training':
      return Icons.Training;
    case 'inventory':
      return Icons.Inventory;
    case 'scheduling':
      return Icons.Schedule;
    default:
      return Icons.AppGrid;
  }
}