import { gql, useQuery } from "@apollo/client";
import { AtomSpinner, Button, Card, Cell, Colors, ErrorPage, Icon, Icons, InfoModal, ModalLauncher, NoPermission, OrgRoles, Products, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, View, useAuthState } from "@barscience/global-components";

/* Get Plans Query */
const GET_PLANS = gql`
query getPlansForOrg($orgId: ID!) {
  org(id: $orgId) {
    plans {
      plan {
        id
        name
        type
        cost
        costType
        userLimit
        product {
          id
          name
        }
      }
      countBillableUsers
      nextBillingDate
      estimatedNextPrice
    }
  }
}
`;

type GetPlansResponse = {
  org: {
    plans: Subscription[] | null;
  } | null;
}

type Subscription = {
  plan: Plan;
  countBillableUsers: number;
  nextBillingDate: string | null;
  estimatedNextPrice: string | null;
}

type Plan = {
  id: string;
  name: string;
  type: PlanType;
  cost: string;
  costType: CostType;
  userLimit: number;
  product: {
    id: string;
    name: string;
  };
}

type CostType = 'FLAT' | 'PER_USER';
type PlanType = 'ANNUAL' | 'MONTHLY';

/* Get All Products */
const GET_PRODUCTS = gql`
query getAllProducts {
  products {
    id
    name
    description
  }
}
`;

type GetProductsResponse = {
  products: Product[] | null;
}

type Product = {
  id: string;
  name: string;
  description: string | null;
}

/* Page Settings */
const ALLOWED_ROLES = [OrgRoles.Owner, OrgRoles.BillingManager];

export default function CurrentSubscriptions() {
  const { state } = useAuthState();
  const { data: planData, loading: plansAreLoading, error: planError } = useQuery<GetPlansResponse>(GET_PLANS, {
    variables: {
      orgId: state.user?.org?.id,
    },
    fetchPolicy: 'network-only',
  });
  const { data: productData, loading: productsAreLoading, error: productsError } = useQuery<GetProductsResponse>(GET_PRODUCTS, {
    fetchPolicy: 'network-only',
  });

  const estimatedPriceModal = (
    <InfoModal title='Cost Estimates'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This price is an estimate and is subject to change.</StyledParagraph>
        <StyledParagraph>This price may not include some credits, discounts, refunds, or future user role changes.</StyledParagraph>
      </View>
    </InfoModal>
  );

  if (!state.user?.roles[Products.Org] || !ALLOWED_ROLES.includes(state.user?.roles[Products.Org])) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (planError || productsError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  const availableProducts = productData?.products?.filter((product) => {
    return !planData?.org?.plans?.some((subscription) => subscription.plan.product.id === product.id);
  });

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <StyledHeading tag='h3'>Current Subscriptions</StyledHeading>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {plansAreLoading ?
          <View>
            <AtomSpinner size='medium' />
          </View>
          :
          <Card size='medium'>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Product</TableHeaderCell>
                  <TableHeaderCell>Plan</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '60px' }}># Users</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '100px' }}>User Limit</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '120px' }}>Price</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: '120px' }}>Next Billing Date</TableHeaderCell>
                  <TableHeaderCell style={{ minWidth: 'fit-content' }}>
                    <span>
                      <View style={{ alignItems: 'center', flexDirection: 'row', gap: '4px' }}>
                        <span style={{ minWidth: 'fit-content' }}>Next Price Estimate</span>
                        <ModalLauncher modal={estimatedPriceModal}>
                          {({ openModal }) => (
                            <Icon icon={Icons.CircleInfo} size='small' onClick={openModal} style={{ cursor: 'pointer', minWidth: 'fit-content' }} />
                          )}
                        </ModalLauncher>
                      </View>
                    </span>
                  </TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {planData?.org?.plans?.map((subscription, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ fontWeight: 600 }}>{subscription.plan.product.name}</TableCell>
                    <TableCell style={{ minWidth: 'fit-content', 'text-wrap': 'nowrap' }}>{subscription.plan.name}</TableCell>
                    <TableCell>{subscription.countBillableUsers}</TableCell>
                    <TableCell>{subscription.plan.userLimit ? subscription.plan.userLimit : 'Unlimited'}</TableCell>
                    <TableCell style={{ minWidth: 'fit-content', 'text-wrap': 'nowrap' }}>{subscription.plan.cost}{getCostTypeLabel(subscription.plan.costType)}{getCycleLabel(subscription.plan.type)}</TableCell>
                    <TableCell>{subscription.nextBillingDate ? subscription.nextBillingDate : '----'}</TableCell>
                    <TableCell>{subscription.estimatedNextPrice ? subscription.estimatedNextPrice : '----'}</TableCell>
                    <TableCell>
                      {(subscription.plan.product.id !== 'org' && subscription.plan.product.id !== 'support') &&
                        <Button label='Manage' variant='tertiary' role='link' href={`/subscriptions/${subscription.plan.product.id}`} />
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        }
      </Cell>
      {availableProducts?.length !== 0 && <Cell lg={12} md={8} sm={4} style={{ marginTop: '32px' }}>
        <StyledHeading tag='h5'>Explore Products</StyledHeading>
        {(productsAreLoading || plansAreLoading) ?
          <View>
            <AtomSpinner size='small' />
          </View>
          :
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '32px', marginTop: '16px' }}>
            {availableProducts?.map((product) => (
              <Card size='medium' style={{ boxSizing: 'border-box', maxWidth: 'min(100%, 400px)', minWidth: 'min(100%, 400px)', width: 'min(100%, 400px)', padding: '32px 16px' }} key={product.id}>
                <View style={{ alignItems: 'center', gap: '16px', height: '100%', justifyContent: 'space-between', textAlign: 'center' }}>
                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                    <Icon icon={getProductIcon(product.id)} size='large' />
                    <StyledHeading tag='h6'>{product.name}</StyledHeading>
                  </View>
                  <StyledParagraph style={{ color: Colors.neutral700 }}>{product.description}</StyledParagraph>
                  <Button label='Start Free Trial' variant='secondary' role='link' href={`/subscriptions/${product.id}`} />
                </View>
              </Card>
            ))}
          </View>
        }
      </Cell>}
    </StandardGrid>
  );
}

const getCostTypeLabel = (type: CostType) => {
  switch (type) {
    case 'FLAT':
      return '';
    case 'PER_USER':
      return ' / user';
    default:
      return '';
  }
}

const getCycleLabel = (type: PlanType) => {
  switch (type) {
    case 'ANNUAL':
      return ' / year';
    case 'MONTHLY':
      return ' / month';
    default:
      return '';
  }
}

const getProductIcon = (id: string) => {
  switch (id) {
    case 'inventory': {
      return Icons.Inventory;
    }
    case 'scheduling': {
      return Icons.Schedule;
    }
    case 'training': {
      return Icons.Training;
    }
    default: {
      return Icons.AppGrid;
    }
  }
}