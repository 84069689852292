import { Button, Cell, Colors, NoPermission, OrgRoles, Products, StandardGrid, StyledHeading, StyledParagraph, View, useAuthState } from "@barscience/global-components";
import PaymentSetupProvider from "../../components/PaymentSetupProvider";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";

const ALLOWED_ROLES = [OrgRoles.Owner, OrgRoles.BillingManager];

export default function AddPaymentMethod() {
  return (
    <PaymentSetupProvider>
      <PaymentMethodForm />
    </PaymentSetupProvider>
  );
}

function PaymentMethodForm() {
  const { state } = useAuthState();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(null);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return null;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-methods`,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    }
  }

  if (!state.user?.roles[Products.Org] || !ALLOWED_ROLES.includes(state.user?.roles[Products.Org])) {
    return <NoPermission />;
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <StyledHeading tag='h3'>Add Payment Method</StyledHeading>
      </Cell>
      <Cell lg={8} md={8} sm={4}>
        <PaymentElement />
        {errorMessage && <StyledParagraph style={{ color: Colors.error500 }}>{errorMessage}</StyledParagraph>}
        <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'flex-end', marginTop: '16px' }}>
          <Button label='Cancel' variant='tertiary' role='link' href='/payment-methods' />
          <Button label='Add Payment Method' variant='primary' role='button' action={handleSubmit} disabled={!stripe} style={{ maxWidth: '', minWidth: 'fit-content', width: 'fit-content' }} />
        </View>
      </Cell>
    </StandardGrid>
  );
}